import * as React from 'react'
import loadable from '@loadable/component'
import { v4 as uuid } from 'uuid'

const ChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const Tick = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Tick'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import {
  KeyboardKeys,
  ThemeInterface,
  withTheme,
} from '@thg-commerce/gravity-theme'

import { ErrorMessage } from '../ErrorMessage'
import { LabelText } from '../FormItem/Label'

import {
  Dropdown,
  DropdownButton,
  DropdownElementContainer,
  DropdownIcon,
  DropdownListItem,
  Item,
  OptionsContainer,
  StyledLabel,
  TickContainer,
} from './styles'

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface DropdownI18nText {
  optionalLabel?: string
  requiredError?: string
}

export type OptionsType = {
  key: string | number
  value?: string
  displayText?: string
  icon?: {
    fill: string
    path: string
  }
  customLabel?: string | null
  useDisabledStyling?: boolean
  component?: React.ReactElement
  disabled?: boolean
  target?: HTMLInputElement
  showCustomLabel?: boolean
}

export interface CustomDropdownProps {
  onChange: (value: OptionsType) => void
  options: OptionsType[]
  ['aria-label']: string
  label?: string
  required?: boolean
  placeholder?: string
  selected?: string | number
  disabled?: boolean
  width?: string
  useDefaultDropdownWidth?: boolean
  useCustomFontStyling?: boolean
  darkStyling?: boolean
  darkStylingLabel?: string
  fixPosition?: boolean
  stickyPosition?: boolean
  zIndex?: number
  countrySelectorStyling?: boolean
  testId?: string
  maxDropdownHeight?: string
  removeBlurFocus?: boolean
  hasPlaceholderAsLabel?: boolean
  hasMarginBottom?: boolean
  customLabel?: string
  selectedIconPosition?: IconPosition | IconPosition.RIGHT
  labelCustomComponent?: React.ReactNode | null
  labelHidden?: boolean
  i18nText?: DropdownI18nText
  borderColor?: string
  // @TO-DO REBUILD-xxx this has been added to remove spacing for product options not a good solution
  removeLabelMargin?: boolean
  iconOverride?: {
    svgPath: string
    viewBox: string
    width: string
    height: string
  }
  customErrorMessage?: {
    displayError?: boolean
    errorMessage?: string
  }
  addEntryKey?: boolean
  ['data-testid']?: string
}

const getSelectedItem = (
  options: OptionsType[],
  selectedKey: string | number,
) => options.find((option) => option.key === selectedKey)

const getSelectedIndex = (
  options: OptionsType[],
  selectedKey: string | number,
) => options.findIndex((option) => option.key === selectedKey)

export const CustomDropdown = withTheme(
  (props: CustomDropdownProps & { theme: ThemeInterface }) => {
    const uuidToken = uuid()
    const dropdownItemId = `dropdown-item-${uuidToken}`

    const [selectedItem, setSelectedItem] = React.useState(
      props.selected
        ? getSelectedItem(props.options, props.selected)
        : undefined,
    )
    const [showDropdown, setShowDropdown] = React.useState(false)
    const [focusIndex, setFocusIndex] = React.useState(
      selectedItem
        ? getSelectedIndex(props.options, selectedItem.key)
        : undefined,
    )

    const dropdownRef = React.useRef<HTMLButtonElement>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const isFirstRender = React.useRef<boolean>(true)
    const isDropdownOpened = React.useRef<boolean>(false)
    const optionRefs: {
      [key: string]: React.RefObject<HTMLLIElement>
    } = props.options.reduce((acc, val) => {
      acc[val.key] = React.createRef<HTMLLIElement>()
      return acc
    }, {})

    const scrollToOption = React.useCallback(
      (optionKey: string | number) => {
        if (props.maxDropdownHeight && optionKey && containerRef.current) {
          optionRefs[optionKey].current?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
          })
        }
      },
      [props.maxDropdownHeight, containerRef, optionRefs],
    )

    React.useEffect(() => {
      props.selected &&
        setSelectedItem(getSelectedItem(props.options, props.selected))
    }, [props.selected, props.options])

    React.useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false
        return
      }

      if (props.darkStyling) return

      if (showDropdown) {
        containerRef.current?.focus()
        if (!isDropdownOpened.current && selectedItem?.key) {
          isDropdownOpened.current = true
          scrollToOption(selectedItem.key)
        }
      } else {
        isDropdownOpened.current = false
      }
    }, [
      showDropdown,
      props.darkStyling,
      props.removeBlurFocus,
      selectedItem?.key,
      scrollToOption,
      isDropdownOpened,
    ])

    React.useEffect(() => {
      if (showDropdown) {
        const selectedItemIndex =
          (selectedItem && props.options.indexOf(selectedItem)) || 0
        setFocusIndex(selectedItemIndex)
      }
    }, [showDropdown, props.options, selectedItem])

    React.useEffect(() => {
      if (!showDropdown) {
        !props.removeBlurFocus && dropdownRef.current?.focus()
      }
    }, [showDropdown, props.removeBlurFocus])

    React.useEffect(() => {
      if (props.customErrorMessage?.displayError) {
        dropdownRef.current?.focus()
        dropdownRef.current?.scrollTo()
      }
    }, [props.customErrorMessage?.displayError])

    const handleDropdownButtonKeyDown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case KeyboardKeys.Esc:
          setShowDropdown(false)
          break
        case KeyboardKeys.Enter:
        case KeyboardKeys.ArrowUp:
        case KeyboardKeys.ArrowDown:
        case KeyboardKeys.Spacebar:
          event.preventDefault()
          setShowDropdown((prevState) => !prevState)
          break
        case KeyboardKeys.Tab:
          if (showDropdown) event.stopPropagation()
          setShowDropdown(false)
          break
      }
    }

    const handleDropdownListKeydown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case KeyboardKeys.Enter:
        case KeyboardKeys.Spacebar:
          event.preventDefault()
          event.stopPropagation()
          if (focusIndex !== undefined) {
            props.onChange(props.options[focusIndex])
            setSelectedItem(props.options[focusIndex])
          }
          setShowDropdown(false)
          break
        case KeyboardKeys.Escape:
          event.stopPropagation()
          setShowDropdown(false)
          break
        case KeyboardKeys.ArrowDown:
          event.preventDefault()

          setFocusIndex(
            Math.min((focusIndex || 0) + 1, props.options.length - 1),
          )
          if (focusIndex) {
            scrollToOption(props.options[focusIndex + 1]?.key)
          }
          break
        case KeyboardKeys.ArrowUp:
          event.preventDefault()
          setFocusIndex(Math.max((focusIndex || 0) - 1, 0))
          if (focusIndex) {
            scrollToOption(props.options[focusIndex - 1].key)
          }
          break
        case 'Home':
          setFocusIndex(0)
          break
        case 'End':
          setFocusIndex(Object.keys(props.options).length - 1)
          break
        case KeyboardKeys.Tab:
          setShowDropdown(false)
          break
      }
    }

    const dropdownOptions = props.options.map((entry, index) => {
      const selected = selectedItem?.key === entry.key
      const focused = focusIndex === index

      return (
        <DropdownListItem
          ref={optionRefs[entry.key]}
          darkStyling={props.darkStyling}
          data-testid={`dropdown-option-${index}`}
          role="option"
          id={`${dropdownItemId}-${index}`}
          aria-selected={selected}
          key={`custom-dropdown-option-${index}`}
          onClick={(event: React.MouseEvent<HTMLLIElement>) => {
            event.preventDefault()
            props.onChange(entry)
            setSelectedItem(entry)
            showDropdown && setShowDropdown(false)
          }}
          onMouseEnter={() => {
            setFocusIndex(index)
          }}
          selected={selected}
          focused={focused}
          iconPosition={props.selectedIconPosition}
          useDisabledStyling={entry.useDisabledStyling}
        >
          <DropdownElementContainer>
            {entry.component ? (
              <DropdownIcon data-testid={`dropdown-icon-${index}-generic`}>
                {entry.component}
              </DropdownIcon>
            ) : (
              entry.icon?.path && (
                <DropdownIcon data-testid={`dropdown-icon-${index}`}>
                  <svg width={24} height={24} viewBox="0 0 24 24">
                    <path fill={entry.icon.fill} d={entry.icon.path} />
                  </svg>
                </DropdownIcon>
              )
            )}
            <Item
              customFontStyleOverride={props?.useCustomFontStyling}
              itemFontStyle={entry?.displayText}
            >
              {entry.displayText}
              {entry.customLabel && ` - ${entry.customLabel}`}
            </Item>
          </DropdownElementContainer>

          <TickContainer>
            {selected && (
              <Tick
                data-testid={`${index}-tick`}
                stroke={
                  props.darkStyling
                    ? props.theme.colors.palette.greys.white
                    : props.theme.colors.palette.greys.darker
                }
                strokeWidth="2px"
              />
            )}
          </TickContainer>
        </DropdownListItem>
      )
    })

    const customDropdownItemId = `custom-dropdown-item-${uuidToken}`
    const customDropdownId = `custom-dropdown`
    const customDropdownButtonId = `custom-dropdown-button`

    const currentLabel =
      selectedItem && !props.hasPlaceholderAsLabel ? (
        selectedItem.icon ? (
          [
            <Item id={customDropdownItemId} key={`${customDropdownId}-item`}>
              {selectedItem.displayText}
              {selectedItem.customLabel && ` - ${selectedItem.customLabel}`}
            </Item>,
          ]
        ) : (
          [
            ...(selectedItem.component
              ? [
                  <DropdownIcon key={`${customDropdownId}-icon`}>
                    {selectedItem?.component}
                  </DropdownIcon>,
                ]
              : []),
            <Item
              id={customDropdownItemId}
              key={`${customDropdownId}-item`}
              customFontStyleOverride={props?.useCustomFontStyling}
              itemFontStyle={selectedItem?.displayText}
            >
              {selectedItem.displayText}
              {selectedItem.customLabel && ` - ${selectedItem.customLabel}`}
            </Item>,
          ]
        )
      ) : (
        <Item id={customDropdownItemId} key={`${customDropdownId}-item`}>
          {props.placeholder}
        </Item>
      )

    return (
      <React.Fragment>
        {props.label && (
          <div style={{ display: 'flex' }}>
            <StyledLabel
              darkStyling={props.darkStyling}
              removeLabelMargin={props.removeLabelMargin}
              id={customDropdownId}
              labelCustomComponent={Boolean(props.labelCustomComponent)}
            >
              <LabelText
                required={props.required}
                className={props.labelHidden ? 'hidden' : ''}
                optionalText={
                  (props.i18nText && props.i18nText.optionalLabel) || ''
                }
                disabled={props.disabled}
              >
                {props.label}
              </LabelText>
            </StyledLabel>
            {props.labelCustomComponent ? props.labelCustomComponent : ''}
          </div>
        )}
        <Dropdown
          {...(props.width && { width: props.width })}
          hasMarginBottom={props.hasMarginBottom}
          onBlur={(event: React.FocusEvent) => {
            if (!event.currentTarget.contains(event.relatedTarget as Node)) {
              setShowDropdown(false)
            }
          }}
          data-testid="dropdown"
          hasMessageError={props.customErrorMessage?.displayError}
        >
          <DropdownButton
            countrySelectorStyling={props.countrySelectorStyling}
            darkStyling={props.darkStyling}
            disabled={props.disabled}
            id={customDropdownButtonId}
            ref={dropdownRef}
            data-testid={
              props['data-testid'] || `dropdow-button-${customDropdownId}`
            }
            aria-labelledby={customDropdownItemId}
            aria-haspopup="listbox"
            dropdownOpen={showDropdown}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault()
              !props.disabled && setShowDropdown((prevState) => !prevState)
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              !props.disabled && handleDropdownButtonKeyDown(event)
            }}
            borderColor={props.borderColor}
            hasErrorBorder={props.customErrorMessage?.displayError}
          >
            {currentLabel}
            {!props.disabled && props.iconOverride?.svgPath ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={props.iconOverride.viewBox}
                width={props.iconOverride.width}
                height={props.iconOverride.height}
              >
                <path d={props.iconOverride.svgPath} fillRule="evenodd" />
              </SvgIcon>
            ) : (
              <ChevronDown />
            )}
          </DropdownButton>
          {props.customErrorMessage?.displayError && (
            <ErrorMessage
              id="no-selected-variant"
              error={props.customErrorMessage.errorMessage}
            />
          )}
          {showDropdown && (
            <OptionsContainer
              countrySelectorStyling={props.countrySelectorStyling}
              darkStyling={props.darkStyling}
              width={props.width}
              ref={containerRef}
              tabIndex={-1}
              onKeyDown={(event: React.KeyboardEvent) => {
                !props.disabled && handleDropdownListKeydown(event)
              }}
              title={props.label}
              aria-activedescendant={
                showDropdown ? `${dropdownItemId}-${focusIndex}` : undefined
              }
              role="listbox"
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-options-container`
                  : 'options-container'
              }
              fixPosition={props.fixPosition}
              useDefaultWidth={props.useDefaultDropdownWidth}
              stickyPosition={props.stickyPosition}
              zIndex={props.zIndex}
              maxDropdownHeight={props.maxDropdownHeight}
            >
              {dropdownOptions}
            </OptionsContainer>
          )}
        </Dropdown>
      </React.Fragment>
    )
  },
)
